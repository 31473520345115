import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import PageContent from "../components/single-page/page-content";

const SinglePage = ({ data: { gaiaPage } }) => (
  <Layout>
    <SEO description={gaiaPage.preview_text} title={gaiaPage.name} />
    <PageContent page={gaiaPage}></PageContent>
  </Layout>
);

export default SinglePage;
export const query = graphql`
  query PageQuery($id: String) {
    gaiaPage(id: { eq: $id }) {
      url
      name
      preview_text
      html_content
      title_picture {
        url
        placeholder
      }
      # fields {
      #   title_picture {
      #     childImageSharp {
      #       fluid(maxWidth: 2560, fit: OUTSIDE) {
      #         ...GatsbyImageSharpFluid
      #       }
      #     }
      #   }
      # }
      # For breadcrumbs
      # parent_pages {
      #  url
      #  name
      #}
    }
  }
`;
