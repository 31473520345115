/**
 * Single page component
 */

import React from "react";
import { Image } from "../../image";

const PageContent = ({ page }) => {
  return (
    <>
      <section className="hero-home dark-overlay mb-5">
        <Image
          layout="fullWidth"
          priority={true}
          src={`/fit_outside${page.title_picture.url}`}
          background={page.title_picture.placeholder}
          width={2560}
          alt={page.name}
          className="img-fluid bg-image blog-title-image"
        />
        <div className="container py-7">
          <div className="overlay-content text-center text-white">
            <h1 className="display-3 font-weight-bold text-shadow mb-0 blog-page-title">
              {page.name}
            </h1>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div
              className="text-content"
              dangerouslySetInnerHTML={{
                __html: page.html_content,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageContent;
